<template>
  <div class="header-title">
    <div class="responsive-container">
      <h1 :class="{ centered }">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderTitle',
  props: ['title', 'centered'],
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.header-title {
  position: relative;
  margin-bottom: 60px;
  background: rgb(227,246,255);
  background: linear-gradient(0deg, rgba(227,246,255,1) 0%, rgba(236,249,255,1) 100%);
  padding: 20px 0;
  .responsive-container {
    h1 {
      margin: 0;
      font-size: 2.4em;
      font-weight: 600;
      &.centered {
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .header-title {
    .responsive-container {
      h1 {
        font-size: 1.8em;
      }
    }
  }
}
</style>
