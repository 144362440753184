<template>
  <div class="features">
    <div class="responsive-container">
      <div class="feature" v-for="feature in featuresData" :key="feature.name">
        <div :class="['image', feature.image]"></div>
        <div class="text-container">
          <h2>{{ feature.name }}</h2>
          <p v-html="feature.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import featuresData from '@/data/features.json';

export default {
  name: 'features',
  data() {
    return {
      featuresData,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.features {
  margin-bottom: 80px;
  .feature {
    display: flex;
    align-items: center;
    padding: 60px;
    border: 1px solid #0088ff;
    border-radius: 8px;
    box-shadow: 0px 0px 6px 0px #0088ff73;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    .image {
      width: 160px;
      height: 160px;
      background-size: contain;
      background-repeat: no-repeat;
      &.browser {
        background-image: url('../assets/secure.svg');
      }
      &.savings {
        background-image: url('../assets/savings.svg');
      }
      &.interest {
        background-image: url('../assets/interest.svg');
      }
      &.more {
        background-image: url('../assets/more.svg');
      }
    }
    .text-container {
      flex: 1;
      text-align: left;
      margin-left: 60px;
      h2 {
        font-size: 2em;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
      }
      p {
        font-size: 1.2em;
        line-height: 2em;
        margin: 0;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .features {
    margin-bottom: 80px;
    h1 {
      font-size: 2em;
      margin: 0;
      margin-bottom: 0.8em;
    }
    .images {
      flex-direction: column;
      align-items: center;
      .image {
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .features {
    .feature {
      flex-direction: column;
      padding: 40px 30px;
      .image {
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
        background-position: center;
      }
      .text-container {
        text-align: center;
        margin-left: 0;
      }
    }
  }
}
</style>
