<template>
  <div class="get-started-popup" :class="{ show }">
    <div class="get-started-popup-bg" @click="close()"></div>
    <div class="get-started-popup-card">
      <button class="close" @click="close()"></button>
      <h2>Get the App</h2>
      <p>
        <b>Note:</b> The Pie Savings feature is currently on a wait list. Find an
        invite code from social media by searching
        <b class="hashtag" @click="copyHashtag()">#PieInviteCode</b>
        and join the wait list inside the app.
      </p>
      <div class="images">
        <a
          class="image app-store"
          href="https://apps.apple.com/us/app/pie-browser/id1529362535"
          target="_blank"></a>
        <a
          class="image google-play"
          href="https://play.google.com/store/apps/details?id=com.piebrowser.browser"
          target="_blank"></a>
      </div>
      <div class="hashtag-copied-overlay" :class="{ 'show': showCopied }">
        Hashtag copied!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetStartedPopup',
  props: ['show'],
  data() {
    return {
      showCopied: false,
    };
  },
  methods: {
    close() {
      this.$emit('on-close');
    },
    copyHashtag() {
      const el = document.createElement('textarea');
      el.value = '#PieInviteCode';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.showCopied = true;
      setTimeout(() => { this.showCopied = false; }, 1500);
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.get-started-popup {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 20px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.1s ease;
  transform: translateY(-10px);
  overflow: auto;
  &.show {
    pointer-events: initial;
    opacity: 1;
    transform: none;
  }
  .get-started-popup-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.6);
  }
  .get-started-popup-card {
    position: relative;
    max-width: 420px;
    text-align: center;
    background-color: #fff;
    padding: 30px 50px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    overflow: hidden;
    margin: auto;
    .close {
      position: absolute;
      top: 24px;
      right: 24px;
      appearance: none;
      border: none;
      background: none;
      width: 20px;
      height: 20px;
      background-image: url('../assets/close.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      outline: none;
      cursor: pointer;
      &:active {
        transform: translateY(1px);
        opacity: 0.8;
      }
    }
    h2 {
      font-size: 2em;
      font-weight: 600;
      margin: 0;
      margin-bottom: 20px;
      line-height: 1.4em;
    }
    p {
      line-height: 2em;
      margin: 0;
      color: #96610f;
      margin-bottom: 32px;
      .hashtag {
        cursor: pointer;
      }
    }
    .images {
      display: flex;
      margin-bottom: 20px;
      flex-wrap: wrap;
      justify-content: center;
      .image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 20px;
        cursor: pointer;
        &:active {
          transform: translateY(1px);
          opacity: 0.8;
        }
        &:last-child {
          margin-right: 0;
        }
        &.app-store {
          width: 150px;
          height: 50px;
          background-image: url('../assets/app-store.svg');
        }
        &.google-play {
          width: 168px;
          height: 50px;
          background-image: url('../assets/google-play.png');
        }
      }
    }
    .hashtag-copied-overlay {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.95);
      font-weight: 600;
      color: #0088ff;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.1s ease;
      &.show {
        pointer-events: initial;
        opacity: 1;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .get-started-popup {
    .get-started-popup-card {
      padding: 30px;
      .close {
        top: 14px;
        right: 14px;
      }
      .images {
        flex-direction: column;
        align-items: center;
        .image {
          margin-right: 0;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
