<template>
  <div class="learn">
    <Header />
    <Hero :showButton="false" />
    <About />
    <Features />
    <Supporters />
    <FaqsSection />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Hero from '@/components/Hero.vue';
import About from '@/components/About.vue';
import Features from '@/components/Features.vue';
import Supporters from '@/components/Supporters.vue';
import FaqsSection from '@/components/FaqsSection.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Learn',
  components: {
    Header,
    Hero,
    About,
    Features,
    Supporters,
    FaqsSection,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.learn {
}
</style>
