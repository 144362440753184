<template>
  <div class="about">
    <div class="responsive-container">
      <h1>Get your slice of the <i>pie</i></h1>
      <p>
        Every year, the big browsers like Safari and Firefox earn
        <a href="https://www.investopedia.com/news/google-spend-12-billion-remain-apple-safaris-2019-default-search-engine-gs/"
          target="_blank">
          billions</a> <a
          href="https://www.investopedia.com/articles/investing/041315/how-mozilla-firefox-and-google-chrome-make-money.asp"
          target="_blank">
          of</a> <a
          href="https://uniquebusinessmodels.substack.com/p/27-how-do-web-browsers-make-money"
          target="_blank">
          dollars</a> by sending users to search engine results – and they keep all of that money
        for themselves. Pie pays its users up to <b>80%</b> of what it earns and automatically
        deposits it into a built-in interest-earning savings account. All for free
        and with your privacy 100% protected.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about',
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.about {
  text-align: center;
  margin-bottom: 80px;
  h1 {
    font-size: 2em;
    font-weight: 600;
  }
  p {
    font-size: 1.2em;
    line-height: 2em;
    max-width: 77%;
    margin: 0 auto;
    a {
      color: #0088ff;
      text-decoration: underline;
      &:active {
        opacity: 0.8;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .about {
    p {
      max-width: none;
    }
  }
}
</style>
