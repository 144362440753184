<template>
  <div class="hero">
    <div class="content-container">
      <div class="responsive-container">
        <div class="text-container">
          <h1>
            The first browser that grows cash for you.
          </h1>
          <p>
            Pie is the first web browser with a built-in savings account.
            Earn cash as you browse the web and grow your savings, all for free.
          </p>
          <a v-if="showButton" class="btn" @click="onButtonClick()">Get started</a>
        </div>
        <div class="image-center-container">
          <div class="image-container">
            <div class="phone-shadow"></div>
            <div class="phone"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('on-button-click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.hero {
  position: relative;
  min-height: 600px;
  padding-bottom: 120px;
  margin-bottom: 40px;
  overflow: hidden;
  .content-container {
    padding: 82px 0px 100px 0px;
    background: rgb(227,246,255);
    background: linear-gradient(0deg, rgba(227,246,255,1) 0%, rgba(236,249,255,1) 100%);
    .responsive-container {
      .text-container {
        text-align: left;
        width: 50%;
        h1 {
          margin: 0;
          margin-bottom: 28px;
          font-size: 2.7em;
          font-weight: 600;
        }
        p {
          margin: 0;
          line-height: 2em;
          margin-bottom: 36px;
          font-size: 1.55em;
        }
      }
      .image-container {
        position: absolute;
        right: -220px;
        // bottom: -280px;
        width: 800px;
        height: 630px;
        top: 34px;
        .phone {
          position: absolute;
          z-index: 1;
          top: 0px;
          left: 37px;
          width: 535px;
          height: 546px;
          background-image: url('../assets/phone.png');
          background-size: contain;
          background-repeat: no-repeat;
          animation: animate-phone 1.25s ease 0.5s;
          animation-fill-mode: both;
        }
        .phone-shadow {
          position: absolute;
          z-index: 1;
          top: 358px;
          left: 40px;
          width: 700px;
          height: 280px;
          background-image: url('../assets/phone-shadow.png');
          background-size: contain;
          background-repeat: no-repeat;
          animation: animate-phone-shadow 1.25s ease 0.5s;
          animation-fill-mode: both;
        }
      }
    }
  }
}
@keyframes animate-phone {
  0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}
@keyframes animate-phone-shadow {
  from {
    transform: translateX(140px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}
@media only screen and (max-width: 950px) {
  .hero {
    padding-bottom: 80px;
    .content-container {
      .responsive-container {
        .text-container {
          h1 {
            font-size: 2em;
          }
          p {
            font-size: 1.2em;
          }
        }
        .image-center-container {
          .image-container {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .hero {
    min-height: auto;
    padding-bottom: 100px;
    .content-container {
      padding: 30px 0px 270px 0px;
      .responsive-container {
        .text-container {
          width: auto;
          text-align: center;
          h1 {
            font-size: 1.8em;
            margin-bottom: 20px;
          }
          p {
            font-size: 1.2em;
            margin-bottom: 30px;
          }
        }
        .image-center-container {
          position: relative;
          width: 1px;
          height: 1px;
          left: calc(50% + 40px);
          .image-container {
            top: -100px;
            bottom: auto;
            left: -400px;
            transform: scale(0.5);
          }
        }
      }
    }
  }
}
</style>
