<template>
  <div class="faqs-section">
    <div class="responsive-container">
      <h1 v-if="showHeader">FAQs</h1>
      <div class="items">
        <div class="item" v-for="item in faqsData" :key="item.question">
          <h3>{{ item.question }}</h3>
          <p v-html="item.answer"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faqsData from '@/data/faqs.json';

export default {
  name: 'Faqs Section',
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return { faqsData };
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.faqs-section {
  margin: 0 auto;
  margin-bottom: 80px;
  max-width: 600px;
  text-align: center;
  h1 {
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .items {
    .item {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
      h3 {
        font-size: 1.3em;
        line-height: 2em;
        font-weight: 600;
        margin: 0;
        margin-bottom: 12px;
      }
      p {
        margin: 0;
        font-size: 1.2em;
        line-height: 2em;
      }
    }
  }
}
</style>
