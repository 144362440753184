<template>
  <router-view/>
</template>

<style lang="scss">
body {
  margin: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.responsive-container {
  position: relative;
  max-width: 1000px;
  padding: 0 30px;
  margin: 0 auto;
}
.btn {
  padding: 14px 50px 12px;
  background-color: #0088ff;
  display: inline-block;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 3px 6px 0px #0088ff73;
  font-size: 1.4em;
  cursor: pointer;
  &:active {
    background-color: lighten($color: #0088ff, $amount: 10);
    transform: translateY(2px);
    box-shadow: 0px 1px 3px 0px #0088ff73;
  }
}
@media only screen and (max-width: 768px) {
  .responsive-container {
    padding: 0 20px;
  }
  .btn {
    padding: 12px 40px 10px;
  }
}
</style>
