<template>
  <div class="fixed-header" :class="{ show: showFixedHeader }">
    <div class="responsive-container">
      <div class="fixed-header-container">
        <a class="logo" href="/"></a>
        <a class="btn" @click="onButtonClick()">Get started</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixedHeader',
  data() {
    return {
      showFixedHeader: false,
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showFixedHeader = window.scrollY > 800;
    },
    onButtonClick() {
      this.$emit('on-button-click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.fixed-header {
  position: fixed;
  display: flex;
  top: -80px;
  justify-content: center;
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
  transition: all 0.2s;
  &.show {
    top: 0;
  }
  .responsive-container {
    display: flex;
    flex: 1;
    .fixed-header-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      .logo {
        width: 200px;
        height: 34px;
        background-image: url('../assets/pie-logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }
      .btn {
        padding: 9px 26px 7px;
        font-size: 1.2em;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .fixed-header {
    display: none;
  }
}
</style>
