import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Learn from '@/views/Learn.vue';
import Faqs from '@/views/Faqs.vue';
import Terms from '@/views/Terms.vue';
import Privacy from '@/views/Privacy.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/learn',
    name: 'learn',
    component: Learn,
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs,
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  setTimeout(() => window.scrollTo(0, 0));
  next();
});

export default router;
