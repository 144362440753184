<template>
  <div class="footer">
    <div class="responsive-container">
      <div>
        <router-link to="/faqs">FAQs</router-link> •
        <a href="mailto:contact@piebrowser.com">Contact Us</a> •
        <router-link to="/terms">Terms of Use</router-link> •
        <router-link to="/privacy">Privacy Policy</router-link>
      </div>
      <div class="copyright">
        &copy; {{ new Date().getFullYear() }} Pie Technologies Inc. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.footer {
  margin-bottom: 80px;
  text-align: center;
  div {
    margin-bottom: 20px;
  }
  a {
    margin: 0 14px;
    text-decoration: none;
    color: inherit;
    &:hover {
      color: $primary-color;
    }
  }
  .copyright {
    opacity: 0.5;
  }
}
</style>
