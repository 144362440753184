<template>
  <div class="header">
    <div class="responsive-container">
      <router-link class="logo" to="/"></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.header {
  padding: 20px 0;
  .responsive-container {
    display: flex;
    .logo {
      width: 200px;
      height: 34px;
      background-image: url('../assets/pie-logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &:active {
        transform: translateY(1px);
        opacity: 0.8;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .header {
    padding: 16px 0;
    .responsive-container {
      justify-content: center;
      .logo {
        width: 150px;
        height: 27px;
      }
    }
  }
}
</style>
