<template>
  <div class="faqs">
    <Header />
    <HeaderTitle title="Frequently Asked Questions" :centered="true" />
    <FaqsSection :showHeader="false" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import HeaderTitle from '@/components/HeaderTitle.vue';
import FaqsSection from '@/components/FaqsSection.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Faqs',
  components: {
    Header,
    HeaderTitle,
    FaqsSection,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.faqs {
  .content {
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 600px;
    .items {
      .item {
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
        h3 {
          font-weight: 600;
          margin: 0;
          margin-bottom: 12px;
        }
        p {
          margin: 0;
          line-height: 2em;
        }
      }
    }
  }
}
</style>
